import React, { useState, useEffect } from "react";
import { Formik, ErrorMessage } from "formik";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import Modal from "../../../components/Modals";
import Input from "../../../components/Input/Input";
import requests from "../../../services/requests";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";
import Select from "react-select";
import * as yup from "yup";

registerLocale("pt", pt);

const ModalVehicleRegister = ({ actionModal, openModal, handleSuccess }) => {
    const [load, setLoad] = useState(false);
    const [vehicles, setVehicles] = useState([]);
    const [drivers, setDrivers] = useState([]);

    const listDrivers = () => {
        setLoad(true);
        requests.listUsers({ role: "ROLE_DRIVER" }, 0, 99999).then(list => {
            let options = [];
            list.data.map(driver => {
                return (
                    options.push({
                        value: driver.identifier,
                        label: driver.name,
                    })
                )
            });
            setDrivers(options);
            setLoad(false);
        });
    };

    const listVehicles = () => {
        setLoad(true);
        requests.listVehicles({}, 0, 99999).then(list => {
            let options = [];
            list.data.map(vehicle => {
                return (
                    options.push({
                        value: vehicle.identifier,
                        label: vehicle.licensePlate,
                    })
                )
            });
            setVehicles(options);
            setLoad(false);
        });
    };

    useEffect(() => {
        listDrivers();
        listVehicles();
    }, []);

    return (
        <Modal
            onClose={actionModal}
            size="xs:w-12/12 lg:w-3/12 min-h-50%"
            show={openModal}
            title={
                <div className="flex items-center">
                    <h5 className={`mr-6 text-roxo_oficial font-bold`}>Cadastro de veículo</h5>
                </div>
            }
        >
            <>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        licensePlate: "",
                        userDriver: "",
                        ownership: "",
                    }}
                    validationSchema={yup.object().shape({
                        licensePlate: yup.string().required("Campo obrigatório."),
                        userDriver: yup.string().required("Campo obrigatório.").nullable(),
                        ownership: yup.string().required("Campo obrigatório.").nullable(),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);

                        requests
                            .addVehicle({
                                licensePlate: values.licensePlate,
                                userDriver: values.userDriver.value,
                                ownership: values.ownership.value,
                            })
                            .then(response => {
                                setSubmitting(false);
                                handleSuccess();
                                toast.success(`Veículo ${response.licensePlate} cadastrado com sucesso!`);
                            })
                            .catch(error => {
                                setSubmitting(false);
                            });
                    }}
                >
                    {({ setFieldValue, handleChange, handleSubmit, isSubmitting, values }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="w-full">
                                <label htmlFor="ownership" className="text-roxo_oficial font-bold">
                                    Propriedade
                                </label>
                                <Select
                                    isClearable
                                    name="ownership"
                                    value={values.ownership}
                                    className="block uppercase text-blueGray-600 text-xs font-bold"
                                    placeholder="Selecione uma propriedade"
                                    onChange={option => {
                                        setFieldValue("ownership", option);
                                    }}
                                    options={[
                                        { value: "VENDEMMIA", label: "VENDEMMIA" },
                                        { value: "PROPRIO", label: "PROPRIO" },
                                        { value: "TERCEIRO", label: "TERCEIRO" },
                                    ]}
                                />
                                <ErrorMessage component="label" name="ownership" className="text-red font-light w-full" />
                            </div>
                            <div className="w-full mt-2">
                                <label htmlFor="userDriver" className="text-roxo_oficial font-bold">
                                    Motorista
                                </label>
                                <Select
                                    isClearable
                                    name="userDriver"
                                    value={values.userDriver}
                                    className="block uppercase text-blueGray-600 text-xs font-bold"
                                    placeholder="Selecione um motorista"
                                    onChange={option => {
                                        setFieldValue("userDriver", option);
                                    }}
                                    options={drivers}
                                />
                                <ErrorMessage component="label" name="userDriver" className="text-red font-light w-full" />
                            </div>
                            <div className="w-full mt-2">
                                <label htmlFor="userDriver" className="text-roxo_oficial font-bold">
                                    Placa
                                </label>
                                <Input
                                    name="licensePlate"
                                    type="text"
                                    value={values.licensePlate}
                                    placeholder="Digite uma placa"
                                />
                                <ErrorMessage component="label" name="licensePlate" className="text-red font-light w-full" />
                            </div>
                            <button
                                className="mb-4 bg-roxo_oficial text-white py-2 px-5 rounded-md float-right mt-10"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? (
                                    <>
                                        Salvando <ClipLoader size={10} loading={isSubmitting} />
                                    </>
                                ) : (
                                    <>Salvar</>
                                )}
                            </button>
                        </form>
                    )}
                </Formik>
            </>
        </Modal>
    );
};

export default ModalVehicleRegister;
